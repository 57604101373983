import React, { useState } from "react";
import "./customer_revisit-form.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const   CustomerRevisit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerNames, setCustomerNames] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [error, setError] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Spinner control

  // Handle input change
  const handleInputChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    setSelectedCustomer(null);
    setIsListOpen(value.length >= 5);
    if (value.length >= 5) {
      handleSearchCustomer(value);
    } else {
      setCustomerNames([]);
    }
  };

  // Search customers
  const handleSearchCustomer = (phoneNumber) => {
    if (!phoneNumber) {
      setError("Please enter a phone number.");
      setCustomerNames([]);
      return;
    }

    setLoading(true);
    fetch(`https://siteregistration.inframantra.com/api/v1/customer/getRevisitSearch`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phoneNumber }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setLoading(false);
        if (data.error) {
          setError(data.error);
          setCustomerNames([]);
        } else {
          setError("");
          setCustomerNames(data.allSortedCustomers || []);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("Error searching for customers.");
        setCustomerNames([]);
        console.error("Error searching for customers:", error);
      });
  };

  // Handle customer selection
  function formatDateToDDMMYYYY(dateString) {
    const date = new Date(dateString); // Convert to Date object
    const day = String(date.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based, so +1) with leading zero
    const year = date.getFullYear(); // Get year
    return `${day}-${month}-${year}`; // Format as dd-mm-yyyy
  }
  const handleCustomerSelect = (customer) => {
    // console.log(customer)
    setSelectedCustomer({
      name: customer.name,
      phoneNumber: customer.phoneNumber,
      placeOfVisit: customer.placeOfVisit,
      firstVisit:formatDateToDDMMYYYY(customer.createdAt)

    });
    setIsListOpen(false);
    setError("");
  };

  // Update customer visit
  const handleUpdateCustomer = async (e) => {
    e.preventDefault();

    if (!selectedCustomer) {
      setError("No customer selected.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("https://siteregistration.inframantra.com/api/v1/customer/revisit", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedCustomer),
      });

      const responseData = await response.json();
      setLoading(false);

      if (response.ok) {
        toast.success(responseData.message);
        setTimeout(() => navigate("/form-selection"), 2000);
        setSelectedCustomer(null);
        setPhoneNumber("");
      } else {
        toast.error(responseData.message);
        setError(responseData.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error(`Error updating customer: ${error.message}`);
      setError("Error updating customer.");
    }
  };

  return (
    <div className="customer-revisit-form">
      <div className="customer-revisit-checker">
        <div className="customer-input-check">
          <label htmlFor="customer">Check Customer</label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Enter Phone No or last five digit"
            value={phoneNumber}
            onChange={handleInputChange}
          />
          {isListOpen && customerNames.length > 0 && phoneNumber ? (
            <div className="selection-check">
              <ul>
                {customerNames.map((customer, index) => (
                  <li key={index} onClick={() => handleCustomerSelect(customer)}>
                    {customer.name}--{customer.placeOfVisit}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
      <div className="customer-revisit-update">
        <div className="customer-input-update">
          <input
            type="text"
            value={selectedCustomer ? selectedCustomer.name : ""}
            placeholder="Name of the Customer"
            readOnly
          />
          <input
            type="text"
            value={selectedCustomer ? selectedCustomer.phoneNumber : ""}
            placeholder="Phone No of the Customer"
            readOnly
          />
           <input
            type="text"
            value={selectedCustomer ? selectedCustomer.firstVisit : ""}
            placeholder="First visit"
            readOnly
          />
          <button onClick={handleUpdateCustomer} disabled={loading}>
            {loading ? "Updating..." : "Update Visit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerRevisit;
