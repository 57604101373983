import React from "react";
import * as XLSX from "xlsx";
import "./utils.css";

const ExportToExcel = ({ data }) => {
  console.log(data,"execl data")
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Customers Sheet
    const customers = data.customer.map((customer) => ({
      Name: customer.name,
      "Phone Number": customer.phoneNumber,
      Email: customer.email,
      Address: customer.address,
      City: customer.city,
      Pincode: customer.pincode,
      "Meeting With": customer.meetingWith,
      "Place of Visit": customer.placeOfVisit,
      "CP Enrolled": customer.cpEnrolled,
      Requirement: customer.requirement,
      Budget: customer.budget,
      "Arrived On": customer.createdAt.substring(0, 10),
      "Visit Count" : customer.visitCount,
      "Visit dates" : customer.visits ? customer.visits.join(", ") : "No visits"
    }));
    const multipleVisit= data.customer.filter((item)=>item.visitCount>1).map((customer) => ({
      Name: customer.name,
      "Phone Number": customer.phoneNumber,
      "Meeting With": customer.meetingWith,
      "Place of Visit": customer.placeOfVisit,
      "CP Enrolled": customer.cpEnrolled,
      Requirement: customer.requirement,
      Budget: customer.budget,
      "Arrived On": customer.createdAt.substring(0, 10),
      "Visit Count" : customer.visitCount,
      "Visit dates" : customer.visits ? customer.visits.join(", ") : "No visits"
    }));

 

    const customersSheet = XLSX.utils.json_to_sheet(customers);
    XLSX.utils.book_append_sheet(wb, customersSheet, "Customers");

    // Channel Partners Sheet
    const channelPartners = data.channelPartner.map((partner) => ({
      Name: partner.name,
      "Phone Number": partner.phoneNumber,
      "Company Name": partner.companyName,
      "Meeting With": partner.meetingWith,
      "Place of Visit": partner.placeOfVisit,
      "Arrived On": partner.createdAt.substring(0, 10),
    }));

    const channelPartnersSheet = XLSX.utils.json_to_sheet(channelPartners);
    XLSX.utils.book_append_sheet(wb, channelPartnersSheet, "Channel Partners");
    
    const multiplevisits = XLSX.utils.json_to_sheet(multipleVisit);
    XLSX.utils.book_append_sheet(wb, multiplevisits, "multiple visits");

    XLSX.writeFile(wb, `Site Registeration Data ${currentDate}.xlsx`);
  };

  return (
    <div>
      <button className='jsonToExcelBtn' onClick={exportToExcel}>
        Export to Excel
      </button>
    </div>
  );
};

export default ExportToExcel;
