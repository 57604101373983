import React from "react";


const DashboardData = ({dashData, activeTab,  handleRowClick, rowSelect,}) => {

    // const data = dashData;
    const fetchDateFromUTC = (date) => {
      // Your fetchDateFromUTC function implementation
      const dateObject = new Date(date);
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      const year = dateObject.getFullYear();
      return `${day < 10 ? "0" : ""}${day} / ${
        month < 10 ? "0" : ""
      }${month} / ${year}`;
    };
    const multipleVisit = (dashData?.customer?.filter(item => Number(item.visitCount || 0) > 1) || []).sort((a,b)=>new Date(b.visits[b.visits?.length-1])-new Date(a.visits[a.visits?.length-1]));
  
    const sortDataByDate = (data) => {
      // Sort data array by date in descending order
      return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    };
    const dashRenderData = (data) => {
        if (activeTab === "customer") {
          return (
            <>
              {/* Render customer data here */}
              <table>
                <thead>
                  <tr>
                    <th>Sr No:</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Price</th>
                    <th>Size </th>
                    <th>Visit Count</th>
                    <th>Meeting With</th>
                    <th>CP Enrolled</th>
                 
                  </tr>
                </thead>
                <tbody>
                  {dashData.customer &&
                    dashData.customer.length > 0 &&
                    dashData.customer.map((item, index) => {
                      const date = fetchDateFromUTC(item.createdAt);
                      return (
                        <>
                          <tr
                            key={item.id}
                            onClick={() => handleRowClick(item, index)}
                            className={index === rowSelect ? "selected-row" : ""}
                          >
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            {item.phoneNumber.length > 10 ? <td>{item.lastFiveDigit}</td> : <td>{item.phoneNumber}</td>}
                         
                            <td>{item.createdBy}</td>
                            <td>{date}</td>
                            <td>{item.budget}</td>
                            <td>{item.requirement}</td>
                            <td>{item.visitCount ? item.visitCount : ""}</td>
                           
                            <td>
                              {item.meetingWith
                                ? item.meetingWith
                                : "not available"}
                            </td>
                            <td>{item.cpEnrolled ? item.cpEnrolled : ""}</td>
                          
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          );
        } 
        else if (activeTab === "multivisit") {
          return (
            <>
              {/* Render customer data here */}
              <table>
                <thead>
                  <tr>
                    <th>Sr No:</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Price</th>
                    <th>Size </th>
                    <th>Visit Count</th>
                    <th>Meeting With</th>
                    <th>CP Enrolled</th>
                 
                  </tr>
                </thead>
                <tbody>
                  {multipleVisit &&
                    multipleVisit.length > 0 &&
                    multipleVisit.map((item, index) => {
                      const date = fetchDateFromUTC(item.createdAt);
                      return (
                        <>
                          <tr
                            key={item.id}
                            onClick={() => handleRowClick(item, index)}
                            className={index === rowSelect ? "selected-row" : ""}
                          >
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            {item.phoneNumber.length > 10 ? <td>{item.lastFiveDigit}</td> : <td>{item.phoneNumber}</td>}
                         
                            <td>{item.createdBy}</td>
                            <td>{date}</td>
                            <td>{item.budget}</td>
                            <td>{item.requirement}</td>
                            <td>{item.visitCount ? item.visitCount : ""}</td>
                           
                            <td>
                              {item.meetingWith
                                ? item.meetingWith
                                : "not available"}
                            </td>
                            <td>{item.cpEnrolled ? item.cpEnrolled : ""}</td>
                          
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          );
        } 
       else  if (activeTab === "customer-sovereign") {
          return (
            <>
              {/* Render customer data here */}
              <table>
                <thead>
                  <tr>
                    <th>Sr No:</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Price</th>
                    <th>Size </th>
                    <th>Visit Count</th>
                    <th>Pin Code</th>
                    <th>Meeting With</th>
                    <th>CP Enrolled</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {dashData.customer &&
                    dashData.customer.length > 0 &&
                    dashData.customer.map((item, index) => {
                      const date = fetchDateFromUTC(item.createdAt);
                      return (
                        <>
                          <tr
                            key={item.id}
                            onClick={() => handleRowClick(item, index)}
                            className={index === rowSelect ? "selected-row" : ""}
                          >
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            {item.phoneNumber.length > 10 ? <td>{item.lastFiveDigit}</td> : <td>{item.phoneNumber}</td>}
                            <td>{item.email}</td>
                            <td>{item.createdBy}</td>
                            <td>{date}</td>
                            <td>{item.budget}</td>
                            <td>{item.requirement}</td>
                            <td>{item.visitCount ? item.visitCount : ""}</td>
                            <td>{item.pincode}</td>
                            <td>
                              {item.meetingWith
                                ? item.meetingWith
                                : "not available"}
                            </td>
                            <td>{item.cpEnrolled ? item.cpEnrolled : ""}</td>
                            <td>{item.address}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          );
        }
        else if (activeTab === "channelPartner") {
          return (
            <>
              <table>
                <thead>
                  <tr>
                    <th>Sr No:</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Meeting With</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Place of Visit</th>
                    <th>Company Name</th>
                    <th>Customers</th>
                  </tr>
                </thead>
                <tbody>
                  {dashData.channelPartner &&
                    dashData.channelPartner.length > 0 &&
                    dashData.channelPartner.map((item, index) => {
                      const date = fetchDateFromUTC(item.createdAt);
                      return (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(item, index)}
                          className={index === rowSelect ? "selected-row" : ""}
                        >
                          <td>{index + 1}</td>
                          <td>{item.name}</td>
                          {item.phoneNumber.length > 10 ? <td>{item.lastFiveDigit}</td> : <td>{item.phoneNumber}</td>}
                          <td>
                              {item.meetingWith
                                ? item.meetingWith
                                : "not available"}
                            </td>
                          <td>{item.createdBy}</td>
                          {/* Assuming the phone number field is "phoneNumber" */}
                          <td>{date}</td>
                          {/* Assuming the address field is "placeOfVisit" */}
                          <td>{item.placeOfVisit}</td>{" "}
                          {/* Assuming "cp_enrolled" corresponds to "companyName" */}
                          <td>{item.companyName ? item.companyName : ""}</td>{" "}
                          <td>
                            <ol>
                              {item.customerNames.map((customerName, idx) => (
                                <li
                                  key={idx}
                                  style={{ marginBottom: "15px" }}
                                >{`${customerName} \n`}</li>
                              ))}
                            </ol>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          );
        }
      };
    return(
        <div>{dashRenderData(dashData)}</div>
    )
}

export default DashboardData;